.left-sidebar-navigation {
	width: 100px;
	height: 100%;
	transform: translateX(-100%);
	box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
	padding-top: 100px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
	transform: translateX(0);
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transition: 0.5s;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
	position: relative;
	z-index: 3;

	&.sub-meu-active {
		box-shadow: none;
	}
	.left-sidebar-nav-item {
		&.active {
			.left-sidebar-option {
				.left-sidebar-option-icon {
					svg {
						color: #736fe2;
					}
				}
				.left-sidebar-option-title {
					h4 {
						color: #736fe2;
					}
				}

				&::after {
					opacity: 1;
					left: 0;
					width: 100%;
				}
			}
		}
	}
	.nav-channels-items {
		background-color: #f5f8ff;
		padding: 15px 0;
		span {
			padding: 8px 8px;
			padding-top: 0;
			margin-bottom: 10px;
			display: block;
			font-size: 12px;
		}
		.left-sidebar-option {
			margin: 5px;
			border: 1px solid #d7e5fa;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			&.flat-style {
				padding: 0;
				height: 60px;
				h4 {
					padding: 0 5px;
				}
				svg {
					font-size: 18px;
				}
			}
		}
	}
}
.left-sidebar-sub-navigation {
	position: absolute;
	top: 0;
	padding-top: 110px;
	width: 200px;
	height: 100%;
	background: #fff;
	left: 130px;
	border-left: 1px solid #f3f3f3;
	background: #fff;
	z-index: 1;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 50%;
		filter: drop-shadow(55px 0 25px rgba(0, 0, 0, 0.02));
		content: "";
		background-color: #fff;
	}
	.sidebar-close {
		position: absolute;
		right: 15px;
		top: 100px;
		z-index: 99999;
		cursor: pointer;
		color: #4c4960;
	}
	.campaign-sub-menu {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 120px;
		left: -10%;
		height: 100%;
		transition: 0s ease-in;
		-webkit-transition: 0s ease-in;
		-moz-transition: 0s ease-in;
		-ms-transition: 0s ease-in;
		-o-transition: 0s ease-in;
		&.active {
			left: 0;
			opacity: 1;
			visibility: visible;
			transition: 0.2s ease-in;
			-webkit-transition: 0.2s ease-in;
			-moz-transition: 0.2s ease-in;
			-ms-transition: 0.2s ease-in;
			-o-transition: 0.2s ease-in;
		}
	}
}

.left-sidebar-sub-navigation {
	left: -200px;
	transition: 0.5s;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
	&.active {
		left: 100px;
	}
}
