.im-input-moment {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	user-select: none;
}
.im-input-moment .options {
	width: 100%;
	height: 40px;
	display: inline-block;
}
.im-input-moment .options button {
	float: left;
	width: 50%;
	color: #826ee9;
	background: 0 0;
	text-align: center;
	font-size: 20px;
	padding: 10px;
	border: 1px solid #826ee9;
}
.im-input-moment .options button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.im-input-moment .options button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.im-input-moment .options button.is-active {
	color: #fff;
	background-color: #826ee9;
}
.im-input-moment .tab-component {
	display: none;
	height: calc(100% - 40px);
}
.im-input-moment .tab-component.is-active {
	display: block;
}
.im-big-input-moment {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border: 1px solid #dfe0e4;
	user-select: none;
}
.im-big-input-moment .date-wrapper {
	width: 100%;
	height: 70%;
}
.im-big-input-moment .time-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 30%;
}
.im-date-picker {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}
.im-date-picker .toolbar {
	height: 40px;
	line-height: 30px;
	color: var(--theme-deafult);
	text-align: center;
	white-space: nowrap;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: none;
	display: flex;
	justify-content: center;
}
.im-date-picker .toolbar .current-date {
	display: inline-block;
	width: 68%;
	min-width: 200px;
	cursor: pointer;
	font-size: 18px;
	line-height: 40px;
	vertical-align: middle;
	color: #7366ff;
}
.im-date-picker .toolbar .next-nav,
.im-date-picker .toolbar .prev-nav {
	display: inline-block;
	width: 8%;
	cursor: pointer;
	font-size: 26px;
	vertical-align: middle;
}
.im-date-picker .next-month,
.im-date-picker .next-year,
.im-date-picker .prev-month,
.im-date-picker .prev-year {
	color: rgba(50, 50, 50, 0.2);
}
.im-date-picker table {
	width: 100%;
	height: calc(100% - 40px);
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
}
.im-date-picker td {
	text-align: center;
	cursor: pointer;
	color: #dfe0e4;
	border: 1px solid #dfe0e4;
	font-size: 14px;
	line-height: 3;
}
.im-date-picker td.current {
	background-color: #826ee9;
	color: #fff;
	font-weight: 700;
}
.im-date-picker thead td {
	color: #826ee9;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 12px;
}
.im-date-picker tbody td {
	color: #666;
}
.im-time-picker {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: #fff;
	padding: 10px 10px 20px 10px;
	border: 1px solid #dfe0e4;
	box-sizing: border-box;
}
.im-time-picker .time-picker-wrapper {
	width: 100%;
}
.im-time-picker .time-picker-wrapper .showtime {
	text-align: center;
}
.im-time-picker .time-picker-wrapper .separator {
	display: inline-block;
	font-size: 32px;
	font-weight: 700;
	color: #826ee9;
	width: 32px;
	height: 65px;
	line-height: 65px;
	text-align: center;
}
.im-time-picker .time-picker-wrapper .time-text {
	position: relative;
	left: -10px;
	font-size: 20px;
	color: #826ee9;
	margin-top: 20px;
	margin-bottom: 10px;
}
.im-time-picker .time-picker-wrapper .sliders {
	padding: 0 10px;
}
.im-time-picker .time-picker-wrapper .time {
	width: 65px;
	height: 65px;
	display: inline-block;
	font-size: 38px;
	line-height: 65px;
	background-color: #826ee9;
	border-radius: 3px;
	text-align: center;
}
.im-time-slider {
	width: 100%;
	color: #fff;
	padding: 10px 10px 20px 10px;
	box-sizing: border-box;
}
.im-time-slider .showtime {
	text-align: center;
}
.im-time-slider .separator {
	display: inline-block;
	font-size: 32px;
	font-weight: 700;
	color: #826ee9;
	width: 32px;
	height: 65px;
	line-height: 65px;
	text-align: center;
}
.im-time-slider .time-text {
	position: relative;
	left: -10px;
	font-size: 20px;
	color: #826ee9;
}
.im-time-slider .sliders {
	padding: 0 10px;
}
.im-time-slider .time {
	width: 65px;
	height: 65px;
	display: inline-block;
	font-size: 38px;
	line-height: 65px;
	background-color: #826ee9;
	border-radius: 3px;
	text-align: center;
}
.im-slider {
	position: relative;
	display: inline-block;
	background-color: #dfe0e4;
	border-radius: 3px;
	height: 4px;
	width: 100%;
	cursor: pointer;
}
.im-slider .value {
	position: absolute;
	background-color: #826ee9;
	border-radius: 3px;
	top: 0;
	height: 100%;
}
.im-slider .handle {
	position: absolute;
	width: 4px;
	height: 4px;
}
.im-slider .handle:after {
	position: relative;
	display: block;
	content: "";
	top: -10px;
	left: -12px;
	width: 24px;
	height: 24px;
	background-color: #fff;
	border: 3px solid #826ee9;
	border-radius: 50%;
	cursor: pointer;
}
.im-btn {
	display: inline-block;
	border: 0;
	outline: 0;
	cursor: pointer;
	line-height: 1;
}
.im-btn:before {
	margin-right: 6px;
}
