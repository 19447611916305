.header-actions-blk {
	display: flex;
	align-items: center;
	h2 {
		font-size: 24px;
	}
	.navbar {
		height: 60px;
		background-color: #f5f8ff;
		padding: 0 1rem;
		border-bottom: #d7e5fa;
		display: flex;
		align-items: center;
	}

	.navbar-nav {
		max-width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.nav-item {
		width: calc(60px * 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.icon-button {
		height: 35px;
		width: 35px;
		max-width: 30px;
		border-radius: 30px;
		min-width: 35px;
	}
	svg {
		fill: #fff;
		width: 20px;
		height: 20px;
	}

	.dropdown {
		border: 1px solid #7091e84d;
		position: absolute;
		top: 71px;
		width: 300px;
		transform: translateX(-45%);
		background-color: #f5f8ff;
		border-radius: 8px;
		padding: 1rem;
		overflow: hidden;
		transition: height var(--speed) ease;
		-webkit-transform: translateX(-45%);
		-moz-transform: translateX(-45%);
		-ms-transform: translateX(-45%);
		-o-transform: translateX(-45%);
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
	}

	.menu {
		width: 100%;
	}

	.menu-item {
		cursor: pointer;
		height: 60px;
		display: flex;
		align-items: center;
		border-radius: 8px;
		color: #7366ff;
		transition: background var(--speed);
		padding: 0.5rem;
		-webkit-transition: background var(--speed);
		-moz-transition: background var(--speed);
		-ms-transition: background var(--speed);
		-o-transition: background var(--speed);
	}

	.menu-item .icon-button {
		margin-right: 0.5rem;
	}

	.menu-item .icon-button:hover {
		filter: none;
	}

	.menu-item:hover {
		background-color: #7366ff;
		color: #fff;
	}

	.icon-right {
		margin-left: auto;
	}

	.menu-primary-enter {
		position: absolute;
		transform: translateX(-110%);
	}
	.menu-primary-enter-active {
		transform: translateX(0%);
		transition: all var(--speed) ease;
	}
	.menu-primary-exit {
		position: absolute;
	}
	.menu-primary-exit-active {
		transform: translateX(-110%);
		transition: all var(--speed) ease;
	}

	.menu-secondary-enter {
		transform: translateX(110%);
	}
	.menu-secondary-enter-active {
		transform: translateX(0%);
		transition: all var(--speed) ease;
	}
	.menu-secondary-exit {
	}
	.menu-secondary-exit-active {
		transform: translateX(110%);
		transition: all var(--speed) ease;
	}
}
