:root {
	--theme-deafult: #7366ff;
	--theme-secondary: #f73164;
	--speed: 500ms;
}
*::-webkit-scrollbar {
	width: 6px;
	background-color: #d7e5fa;
	border-radius: 15px;
}
*::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 3px #d7e5fa;
	border-radius: 15px;
}
* {
	scrollbar-width: thin;
	scrollbar-color: #d7e5fa #fff;
}
*::-webkit-scrollbar-thumb {
	background: #d7e5fa;
	width: 5px;
}
*::-webkit-scrollbar-track {
	background: #fff;
}
.no-shadow {
	box-shadow: none !important;
}
.active-row {
	background: #4697ff40;
}
body {
	font-size: 14px;
	overflow-x: hidden;
	overflow-y: scroll !important;
	font-family: "Rubik", sans-serif;
	color: #2b2b2b;
	background-color: #f5f8ff !important;
}
button {
	background: none;
	border: none;
	cursor: pointer;
}

select::-ms-expand {
	display: none;
}
a:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline: 0;
	outline: none !important;
	box-shadow: none !important;
}
input,
textarea {
	color: #2b2b2b !important;
}

.fancy-date {
	color: rgba(43, 43, 43, 0.7);
	letter-spacing: 0.5px;
	font-size: 14px;

	span {
		font-size: 36px;
		font-weight: 500;
		padding-right: 5px;
	}
}

.left-sidebar-sub-menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 99;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
	&.active {
		opacity: 1;
		visibility: visible;
	}
}
@keyframes anvil {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}

.list_common {
	li {
		color: #3a4456;
		font-size: 12px;
		line-height: 25px;
		margin-bottom: 15px;
		border-bottom: 1px dashed #d7e5fa;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 0;
		&:last-child {
			border-bottom: none;
		}
	}
}

.list_common {
	li {
		color: #3a4456;
		font-size: 12px;
		line-height: 25px;
		margin-bottom: 15px;
		border-bottom: 1px dashed #d7e5fa;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 0;
		&:last-child {
			border-bottom: none;
		}
	}
}

.loader {
	display: flex;
	position: absolute;
	left: 50%;
	transition: opacity 0.3s ease-in;
	bottom: 100px;
	z-index: 99999;
	left: 50%;
	transform: translateX(-50%);
}

.loader.show {
	opacity: 1;
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}
}

.common-title {
	.common-title-inner {
		display: flex;
		align-items: center;
	}
	&.border-bottom-primary {
		padding-bottom: 10px;
	}
	h2 {
		color: #826ee9;
		margin: 0;
		line-height: 1.4;
		font-size: 18px;
	}
	p {
		font-size: 12px;
		display: block;
		margin: 0;
		max-width: unset;
	}
	.common-title-icon {
		height: 30px;
		width: 30px;
		background: #826ee9;
		font-size: 22px;
		border-radius: 4px;
		margin-right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 30px;
		min-width: 30px;
		cursor: pointer;
		svg {
			margin: 0;
			color: #ffff;
			font-size: 16px;
		}
	}
}

.recipient-meta {
	min-height: 510px;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		font-size: 130px;
		color: #26d29e;
	}
	h2 {
		font-size: 18px;
		margin-top: 35px;
		margin-bottom: 0;
		line-height: 1;
		font-weight: 700;
	}
}

//?new
.ant-page-header-heading-title {
	color: #826ee9;
	margin: 0;
	line-height: 1.4;
	font-size: 18px;
}

.ant-page-header-back {
	height: 30px;
	width: 30px;
	background: #826ee9;
	font-size: 22px;
	border-radius: 4px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	min-width: 30px;
	cursor: pointer;
	color: #fff;
}

.ant-page-header-back svg {
	color: #fff;
	font-size: 17px;
}

.async-select-field {
	position: relative;
	z-index: 99;
}

@include tablet {
	.rdrDefinedRangesWrapper {
		display: none;
	}
	.cmp-ovw-icon {
		display: none;
	}

	.campaign-ovw-itm .cmp-ovw-info {
		display: block;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-txt {
		max-width: 100%;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-facts {
		max-width: 100%;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 30px;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-facts .cmp-ovw-fact {
		padding: 9px 0;
	}
}

@include mobile {
	.rdrDefinedRangesWrapper {
		display: none;
	}
	.cmp-ovw-icon {
		display: none;
	}

	.campaign-ovw-itm .cmp-ovw-info {
		display: block;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-txt {
		max-width: 100%;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-facts {
		max-width: 100%;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 30px;
	}

	.campaign-ovw-itm .cmp-ovw-info .cmp-ovw-facts .cmp-ovw-fact {
		padding: 9px 0;
	}
}

.bottom-navigation-blk {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background: #fff;
	z-index: 999;
	box-shadow: 0px 0px 20px 0px #150f0f54;
	border-top: 1px solid #2525254d;
	.bottom-navs {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 65px;
		.btm-nav-itm {
			text-align: center;
			flex: 0 0 20%;
			max-width: 20%;
			position: relative;

			&.active {
				svg,
				p {
					color: #826ee9;
				}
			}
			.main-btm-nav {
				position: absolute;
				height: 70px;
				width: 70px;
				background: white;
				top: -50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50px;
				box-shadow: 0 0 10px #dadada;
				padding: 10px;
				left: 50%;
				margin-left: -35px;
			}
			svg {
				font-size: 20px;
				color: #a6a8b7;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
			}
			p {
				color: #a6a8b7;
				font-size: 11px;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
				margin-bottom: 0;
			}
		}
	}
}

.ant-card-head-title {
	padding: 0;
	font-size: 20px;
	font-weight: 600;
}

.error-not-found {
	height: calc(100vh - 60px);
	width: calc(100vw - 100px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin: 0 auto;
	.error-404-txt {
		h1 {
			font-size: 200px;
			color: #736fe2;

			letter-spacing: -2px;
		}
		h4 {
			font-size: 16px;

			margin: 0;
			line-height: 1.4;
		}
		button {
			margin-top: 60px;
		}
	}

	//!
}

//?new

.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 1000ms ease-in;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.recipient-option {
	border: initial;
	background: #fff;
	border-radius: calc(0.15rem - 1px);
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
	padding: 15px 20px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	margin-bottom: 15px;
	border-bottom: 1px dashed #99c4ef;
	width: 100%;
	cursor: pointer;
	.recipient-item-title {
		display: flex;
		align-items: center;
		cursor: pointer;
		position: relative;

		.recipient-icon {
			margin-right: 15px;
		}
		svg {
			font-size: 20px;
			color: #736fe2;
		}
		h4 {
			color: #3a4456;
			font-size: 12px;
			margin: 0;
			line-height: 1;

			span {
				color: #3a4456b8 !important;
				font-size: 10px;
				margin: 0;
				display: block;
				line-height: 1.2;
				margin-top: 4px;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
			}
		}
	}
}
.css-trans-secondary-enter {
	display: block;
}
.css-trans-secondary-enter-active {
	display: block;
}

.css-trans-secondary-exit {
	display: none;
}

.css-trans-secondary-exit-active {
	display: none;
}
.select-channels {
	display: flex;
	margin: 0 -10px;
	flex-wrap: wrap;
	.cnl-crd {
		flex: 0 0 50%;
		max-width: 50%;
		display: flex;
		justify-content: center;
		padding: 10px;
	}
}
.campaign-ovw-itm {
	background: #fff;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;
	border: 1px solid #d7e5fa;
	&.active {
		border: 2px solid #7366ff;
	}
	.cmp-ovw-icon {
		width: 70px;
		text-align: center;
		flex: 0 0 70px;
		max-width: 70px;
		svg {
			font-size: 30px;
			color: #736fe2;
		}
	}

	.cmp-ovw-info {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		border-left: 1px solid #d7e5fa;
		padding: 7px 30px;
		.cmp-ovw-txt {
			flex: 0 0 30%;
			max-width: 30%;
			span {
				font-size: 12px;
				font-weight: 500;
				color: #0c0c0d59;
				line-height: 1.5;
				display: block;
			}
			h4 {
				font-size: 16px;
				line-height: 1.2;
				margin: 0;
				margin-bottom: 10px;
				margin-top: 5px;
				color: #736fe2;
				font-weight: 600 !important;
			}
		}

		.cmp-ovw-facts {
			display: flex;
			display: flex;
			justify-content: space-between;
			flex: 0 0 40%;
			max-width: 40%;
			align-items: center;
			.cmp-ovw-fact {
				flex: 0 0 25%;
				text-align: center;
				span {
					font-size: 13px;
					line-height: 1;
					text-transform: uppercase;
					margin-top: 10px;
					display: block;
					font-weight: 600 !important;
					color: #0c0c0d59;
				}
				h4 {
					font-size: 14px;
					line-height: 1.2;
					margin: 0;
					opacity: 0.9;
					font-weight: 600 !important ;
					text-transform: capitalize;
				}
			}
		}
	}
}
.verify-code-blk {
	input {
		max-width: 45px !important;
		height: 60px;
		margin: 0 5px;
		font-size: 2rem;
		border-radius: 4px;
		border: 1px solid #d7e5fa;
		width: 100% !important;
		transition: 0.3s;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;
		&:focus {
			border-color: #736fe2;
		}
	}
}
label {
	font-size: 14px;
	margin-bottom: 10px;
	display: inline-block;
}
.common-list-wrapper {
	width: 100%;
	.cmp-rvw-list {
		padding: 13px 0px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px dashed #d8e6f4a6;
		&:last-child {
			border-bottom: none;
		}
		svg {
			font-size: 16px;
			margin-right: 8px;
			color: #826ee9;
		}
		.lst-title {
			font-weight: 500;
		}
		span {
			font-weight: 500;
			display: flex;
			font-size: 12px;
			align-items: flex-start;
			&:last-child {
				flex: 0 0 50%;
				max-width: 50%;
				justify-content: flex-end;
			}
		}
	}
}
