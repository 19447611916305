.countdown {
	padding: 30px 0;

	.time {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $white;
		font-weight: 500;
		width: 100px;
		height: 100px;
		font-size: 36px;
		background: var(--theme-deafult);
	}

	.title {
		padding-top: 13px;
		font-size: 14px;
		font-weight: 400;
		color: rgba(#2b2b2b, 0.7);
		text-transform: uppercase;
		letter-spacing: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	ul li {
		display: inline-block;
		text-transform: uppercase;
		margin: 0 10px;

		span {
			display: block;
		}
	}
}
