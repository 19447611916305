.ant-card {
	margin-bottom: 30px;
	border: none;
	transition: all 0.3s ease;
	letter-spacing: 0.5px;
	border-radius: 15px;
	box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
	&:hover {
		box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
		transition: all 0.3s ease;
	}
	.ant-card-head {
		background-color: #fff;
		padding: 15px 20px;
		border-bottom: 1px solid #ecf3fa;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		position: relative;
		&.card-no-border {
			border-bottom: none !important;
		}
		h5 {
			font-size: 22px;
			&:not(.mb-0),
			&:not(.m-0) {
				margin-bottom: 0;
				text-transform: capitalize;
			}
		}
		> span {
			font-size: 12px;
			color: rgba(#2b2b2b, 0.7);
			margin-top: 5px;
			display: block;
			letter-spacing: 1px;
		}
		.card-header-right {
			border-radius: 0 0 0 7px;
			right: 35px;
			top: 33px;
			display: inline-block;
			float: right;
			padding: 8px 0;
			position: absolute;
			background-color: #fff;
			z-index: 1;
			.card-option {
				text-align: right;
				width: 35px;
				height: 20px;
				overflow: hidden;
				transition: 0.3s ease-in-out;
				li {
					display: inline-block;
					&:first-child {
						i {
							transition: 1s;
							font-size: 16px;
							color: var(--theme-deafult);
							&.icofont {
								color: unset;
							}
						}
					}
				}
			}
			i {
				margin: 0 5px;
				cursor: pointer;
				color: $dark-color;
				line-height: 20px;
				&.icofont-refresh {
					font-size: 13px;
				}
			}
		}
	}
	.card-body,
	.ant-card-body {
		padding: 15px 20px;
		background-color: transparent;
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.sub-title {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding-bottom: 5px;
		margin-bottom: 8px;
		font-size: 18px;
	}
	.card-footer {
		background-color: #fff;
		border-top: 1px solid #ecf3fa;
		padding: 15px 20px;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}
	&.card-load {
		.card-loader {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			background-color: rgba(255, 255, 255, 0.7);
			z-index: 8;
			align-items: center;
			justify-content: center;
			i {
				margin: 0 auto;
				color: var(--theme-deafult);
				font-size: 20px;
			}
		}
	}
	&.full-card {
		position: fixed;
		top: 0;
		z-index: 99999;
		box-shadow: none;
		right: 0;
		border-radius: 0;
		border: 1px solid #efefef;
		width: calc(100vw - 12px);
		height: calc(100vh);
		.card-body,
		.ant-card-body {
			overflow: auto;
		}
	}
}
.card-absolute {
	margin-top: 20px;
	.ant-card-head {
		position: absolute;
		top: -20px;
		left: 15px;
		border-radius: 0.25rem;
		padding: 10px 15px;
		h5 {
			font-size: 17px;
		}
	}
	.card-body,
	.ant-card-body {
		margin-top: 10px;
	}
}
.ant-card-head {
	.border-tab {
		margin-bottom: -13px;
	}
}
.custom-card {
	overflow: hidden;
	padding: 30px;
	.ant-card-head {
		padding: 0;
		img {
			border-radius: 50%;
			margin-top: -100px;
			transform: scale(1.5);
		}
	}
	.card-profile {
		text-align: center;
		img {
			height: 150px;
			padding: 7px;
			background-color: #fff;
			z-index: 1;
			position: relative;
		}
	}
	.card-social {
		text-align: center;
		li {
			display: inline-block;
			padding: 15px 0;
			a {
				padding: 13px;
				color: rgb(188, 198, 222);
				font-size: 16px;
				transition: all 0.3s ease;
				&:hover {
					color: var(--theme-deafult);
					transition: all 0.3s ease;
				}
			}
		}
	}
	.profile-details {
		h6 {
			margin-bottom: 30px;
			margin-top: 10px;
			color: rgba(#2b2b2b, 0.7);
			font-size: 14px;
		}
	}
	.card-footer {
		padding: 0;
		> div {
			+ div {
				border-left: 1px solid #efefef;
			}
			padding: 15px;
			text-align: center;
			h3 {
				margin-bottom: 0;
				font-size: 24px;
			}
			h6 {
				font-size: 14px;
				color: rgba(#2b2b2b, 0.7);
			}
			i {
				font-size: 24px;
				display: inline-block;
				margin-bottom: 15px;
			}
			.m-b-card {
				margin-bottom: 10px;
			}
		}
	}
}
