.left-sidebar-option {
	text-align: center;
	cursor: pointer;
	position: relative;
	border-bottom: 1px solid #f3f3f3;
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	align-items: center;
	z-index: 9999;
	.left-sidebar-option-icon {
		svg {
			font-size: 22px;
			color: #3a4460;
		}
	}
	.left-sidebar-option-title {
		h4 {
			text-align: center;
			padding: 0 10px;
			line-height: 1.4;
			color: #3a4460;
			font-size: 11px;
			font-style: normal;

			margin-top: 5px;
		}
	}
	&:last-child {
		border-bottom: none;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 2px;
		width: 0%;
		transform: translateY(-50%);
		background-color: #736fe2;
		content: "";
		transition: 0.4s;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		-ms-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
	}
	&:hover {
		.left-sidebar-option-icon {
			svg {
				color: #736fe2;
			}
		}
		.left-sidebar-option-title {
			h4 {
				color: #736fe2;
			}
		}

		&::after {
			opacity: 1;
			left: 0;
			width: 100%;
		}
	}

	&.flat-style {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: auto;
		padding: 10px 20px;
		padding-right: 8px;
		&:hover {
			.left-sidebar-option-icon {
				svg {
					color: #736fe2;
				}
			}
			.left-sidebar-option-title {
				h4 {
					color: #736fe2;
				}
			}
		}
		.left-sidebar-option-icon {
			svg {
				font-size: 18px;
				color: #3a4460;
			}
		}
	}
}
