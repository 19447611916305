.trigger {
	padding: 0 33px;
	font-size: 20px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.app-header-right-block {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 14px;
}
.app-layout {
	.site-layout-background {
		background: #fff;
	}
}

.app-sidebar {
	height: 100vh;
	position: fixed;
	left: 0;
	z-index: 999;
}
.ant-layout-sider {
	background: none;
}
.ant-layout-sider-collapsed {
	width: 0 !important;
	min-width: 0 !important;
	.left-sidebar-navigation {
		left: -100px;
		transition: 0.4s;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		-ms-transition: 0.4s;
		-o-transition: 0.4s;
	}
}
.app-header {
	border-bottom: 1px solid #dddddd73;
	position: fixed;
	z-index: 999;
	width: 100%;
	padding: 0;
	background: #fff;
}
.app-content {
	margin: 10px;
	padding: 24px;
	margin-bottom: 0;
	min-height: 100vh;
	margin-top: 74px;
	.ant-page-header {
		padding: 0;
		padding-bottom: 12px;
		border-bottom: 1px solid #dddddd73;
		margin-bottom: 12px;
	}
}

.app-right-sidebar {
	position: fixed;
	right: 0;
	top: 0;
	background: #fff;
	height: 100%;
	z-index: 99;
	padding: 14px;
	padding-top: 74px;
	box-shadow: 0 0 10px #ddd;
}
.app-right-sidebar-toggle-icon {
	position: fixed;
	top: 110px;
	background: #fff;
	right: 248px;
	box-shadow: 0 0 10px #ddd;
	height: 40px;
	width: 40px;
	display: flex;
	font-size: 24px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid #ddd;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	z-index: 999;
}
.ant-layout-sider-collapsed.app-right-sidebar {
	right: -100%;
}
.ant-layout-footer {
	text-align: center;
	padding: 15px 0;
}
.ant-layout-footer p {
	margin: 0;
	line-height: 1;
}
