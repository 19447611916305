.message-module-right-sidebar-mobile-ui {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	.mobilescreen {
		width: 230px;
		height: 480px;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border: 2px solid #7366ff;
		padding: 20px 5px 100px 5px;
		border-radius: 30px;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		-ms-border-radius: 30px;
		-o-border-radius: 30px;
	}

	.maincontainer {
		width: 100%;
		margin: 0 auto;
		border: 1px solid #d7e5fa;
		position: relative;
		overflow: hidden;
		height: 100%;

		height: 387px;
	}

	.mainscreen {
		position: absolute;
		height: 100%;
		left: 0px;
		top: 0px;
		width: 100%;

		transition: 0.2s;
	}

	.controlder {
		width: 120px;
		height: 35px;
		border: 2px solid #7366ff;
		border-radius: 50%;
		height: 40px;
		width: 40px;
		margin: 0 auto;
		margin-top: 5px;
	}

	.speaker {
		width: 50px;
		height: 5px;
		border-radius: 68px;
		background: #f2f2f2;
		margin: 0px auto 15px;
		box-shadow: inset 0px 0px 10px 0px #7366ff, inset 0px 5px 0px 0px #7366ff;
		border: 1px solid #7366ff;
	}

	.mobile-ui-single-msg {
		.title {
			h4 {
				font-size: 14px;
				color: #303f9f;
				margin: 0;
				line-height: 1;
				padding: 10px 15px;
				border-bottom: 1px solid #d7e5fa;

				svg {
					color: #303f9f;
				}
			}
		}
		.sngl-msg {
			padding: 12px;
			span {
				font-size: 9px;
				line-height: 1;
				display: block;
				margin-bottom: 5px;
				text-align: right;
				color: #d7e5fa;
				opacity: 0.7;
			}
			p {
				margin: 0;
				font-size: 12px;
				color: #ffff;
				line-height: 1.4;
				background: #7366ff;
				padding: 15px;
				border-radius: 10px 10px 10px 0;
				margin-bottom: 15px;
				-webkit-border-radius: 10px 10px 10px 0;
				-moz-border-radius: 10px 10px 10px 0;
				-ms-border-radius: 10px 10px 10px 0;
				-o-border-radius: 10px 10px 10px 0;
			}
		}
	}
}
