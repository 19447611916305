.channel-item {
	background: #fff;
	min-width: 150px;
	min-height: 155px;
	width: 100%;
	border-radius: 10px;
	filter: drop-shadow(0 0 25px #f1f1fa);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.7s cubic-bezier(0.26, 0.76, 0.3, 0.75);
	box-shadow: 0 50px 80px -10px rgba(0, 0, 0, 0.021);
	&:hover {
		transform: translate3d(0, -10px, 0);
	}
	.channel-title {
		font-size: 14px;

		margin: 0;
		line-height: 1;
		margin-top: 10px;
	}

	.channel-icon {
		height: 55px;
		width: 55px;
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			font-size: 30px;
			color: #fff;
		}
		&.bg-royalBlue {
			&::after {
				background: #008ffb;
			}
		}
		&.bg-algalFuel {
			&::after {
				background: #00e396;
			}
		}
		&.bg-flirtatious {
			&::after {
				background: #feb019;
			}
		}
		&.bg-fusionRed {
			&::after {
				background: #ff4560;
			}
		}
		&.bg-glommyPurple {
			&::after {
				background: #8e44ad;
			}
		}
		&.bg-reptileGreen {
			&::after {
				background: #0fb9b1;
			}
		}
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(
				109.6deg,
				rgba(218, 185, 252, 1) 11.2%,
				rgba(125, 89, 252, 1) 91.1%
			);
			content: "";
			border-radius: 50%;
			z-index: -1;
		}
	}
	.dd {
		color: #2b219c;
	}
}
