/**=====================
     27. Coming Soon CSS Start
==========================**/
.comingsoon-bgimg {
	// background: url(../../images/other-images/coming-soon-bg.jpg);
	background-position: bottom;
	background-size: cover;
}

.comingsoon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 50px 0;
	img {
		max-width: 250px;
	}
	.comingsoon-inner {
		h5 {
			font-size: 18px;
			letter-spacing: 1px;
			color: #7366ff;
			font-weight: 500;
			margin-bottom: 30px;
			margin-top: 30px;
		}
	}

	video {
		position: fixed;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -100;
		transform: translateX(-50%) translateY(-50%);
		transition: 1s opacity;
	}
}

/**=====================
    27. Coming Soon CSS Ends
==========================**/
